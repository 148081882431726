<template>
  <div class="zt-page-content">
    <div class="page-header">备料调研</div>
    <div class="block-header flex">
      <div>已提交调研表单企业</div>
      <div>{{survey.submitedCount}}</div>
    </div>
    <div class="page-overview flex flex-justify-between">
      <el-card class="box-card">
        <div class="title">
          <div>指接板</div>
          <div>主要木材使用的企业数</div>
        </div>
        <div id="sort_first_list"></div>
      </el-card>
      <el-card class="box-card">
        <div class="tab_menu flex">
          <div
            v-for="(item,index) in tabList"
            :key="index"
            @click="tab(item)"
            class="menu_item"
            :class="{'cur':tabIndex == item.spec}"
          >
            <span>{{item.name}}</span>
          </div>
        </div>
        <div id="sort_second_list"></div>
      </el-card>
      <el-card class="box-card">
        <div class="title">
          <div>直条料</div>
          <div>主要木材使用的企业数</div>
        </div>
        <div id="sort_third_list"></div>
      </el-card>
    </div>
    <!-- 头部筛选条件 -->
    <div class="box-card-head flex">
        <div class="flex-1"></div>
        <el-input
            size="mini"
            style="width:300px"
            placeholder="请输入搜索关键字"
            @change="companyTypeChanged"
            clearable
            v-model="pageConfig.where.compName"
        >
            <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input> 
    </div>
    <div class="page-body" ref="load">
      <div class="list-item" v-for="(item,idx) in list" :key="idx">
          <div class="list-item-head">
              <div class="flex">
                  <div class="flex-1"><label>提交日期：</label><span v-if="item.createdAt">{{item.createdAt | moment("YYYY/MM/DD")}}</span></div>
                  <div class="flex-1"><label>企业：</label><span v-if="item.comp">{{item.comp.name}}</span></div>
                  <div class="flex-1"><label>现阶段每月销售额（万元）：</label><span v-if="item.detail">{{item.detail.monthSaleAr}}</span></div>
                  <div class="flex-1"><label>备料预算流转资金（万元）：</label><span v-if="item.detail">{{item.detail.monthBlAmt}}</span></div>
              </div>
              <div class="flex">
                  <div class="flex-1 flex"><label>疫情对企业的影响：</label>
                    <div v-if="item.detail && item.detail.effect && item.detail.effect.indexOf('6')>-1">无影响</div>
                    <div>
                        <el-popover
                            placement="top-start"
                            title="影响"
                            width="300"
                            trigger="hover"
                            :content="item.context">
                            <a slot="reference">查看影响</a>
                        </el-popover>
                    </div>
                  </div>
                  <div class="flex-1"><label>目前线上运营尝试：</label><span v-if="item.detail">{{item.detail.online_cs || '无'}}</span></div>
                  <div class="flex-1"><label>是否需要金融解决方案支持：</label><span v-if="item.detail && item.detail.needJr">需要</span>
                    <span v-else>不需要</span>
                  </div>
                  <div class="flex-1"><label>是否愿意在备料中心进行备料：</label><span v-if="item.detail && item.detail.needBl">是</span>
                    <span v-else>否</span>
                  </div>
              </div>
          </div>
          <div class="list-item-box">
              <div class="title">指接板</div>
              <el-table v-if="item.detail && item.detail.zjb" :data="item.detail.zjb.specs" class="table">
                    <el-table-column label="主要木材">
                        <div v-if="item.detail.zjb.woodKind">{{item.detail.zjb.woodKind.name}}</div>
                    </el-table-column>
                    <el-table-column prop="spec" label="规格" width="120">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type=='aa'">AA板</span>
                            <span v-if="scope.row.type=='ab'">AB板</span>
                            <span v-if="scope.row.type=='bj'">白节板</span>
                            <span v-if="scope.row.type=='cb'">C 板</span>
                            <span>-{{scope.row.spec}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="count" label="数量（方/月）">
                    </el-table-column>
                    <el-table-column prop="price" label="单价（元/方）" width="120">
                    </el-table-column>
                </el-table>
          </div>
          <div class="list-item-box">
              <div class="title">直条料</div>
              <el-table v-if="item.detail && item.detail.ztl" :data="item.detail.ztl.products" class="table">
                    <el-table-column label="主要木材">
                        <div v-if="item.detail.ztl.woodKind">{{item.detail.ztl.woodKind.name}}</div>
                    </el-table-column>
                    <el-table-column prop="name" label="产品名称">
                    </el-table-column>
                    <el-table-column prop="bjName" label="部件名称">
                    </el-table-column>
                    <el-table-column prop="size" label="规格尺寸（cm）">
                        <template slot-scope="scope">
                            <span v-if="scope.row.size">{{scope.row.size.len}}*{{scope.row.size.width}}*{{scope.row.size.height}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="count" label="数量（方/月）">
                    </el-table-column>
                    <el-table-column prop="price" label="单价（元/方）" width="120">
                    </el-table-column>
                </el-table>
          </div>
      </div>
    </div>
    <el-pagination
        style="background:#fff;padding:10px 0;"
        background
        layout="prev, pager, next"
        @current-change="companyTypeChanged"
        :current-page.sync="pageConfig.page.pageNumber"
        :page-size="pageConfig.page.pageCount"
        :total="pageConfig.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import echarts from "echarts";
import { surveyStatistics, surveyList } from "@/service/bureau";
import { catList, styleList } from "@/service/dictionary";
import { IMG_URL_PRE } from "@/config";

export default {
  components: {},
  data() {
    const selectData = [
      { label: "南康1", value: 1 },
      { label: "南康2", value: 2 },
    ];
    return {
      IMG_URL_PRE,
      tabList: [
        { spec: "aa", name: "AA板" },
        { spec: "ab", name: "AB板" },
        { spec: "bj", name: "白节板" },
        { spec: "cb", name: "C 板" },
      ],
      tabIndex: "aa",
      survey: {},
      pageConfig: {
        where: {
          compName:"",
        },
        page: {
          pageNumber: 1,
          pageCount: 15,
        },
        total: 15,
      },
      list: [],
      select:[
        {id:1,name:'招工困难'},
        {id:2,name:'同比营销额下降'},
        {id:3,name:'市场消费需求低'},
        {id:4,name:'备料、成品仓库积压'},
        {id:5,name:'资金周转困难'},
        {id:6,name:'无影响'},
        {id:7,name:'其他'},
      ],
      key: "", 
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    // 选项卡
    tab(item) {
      this.tabIndex = item.spec; 
      this.aaBan();
    },
    /**
     * @desc 初始化页面
     * @return void
     */
    init() {
      surveyStatistics().then((data) => {
        console.log(data);
        this.survey = data;
        //指接板初始化
        if (data.zjb && data.zjb.length > 0) {
          let dataX = data.zjb.map((item) => item.name);
          let dataY = data.zjb.map((item) => item.count);
          this.renderSortCard("sort_first_list", {
            dataX: dataX,
            dataY: dataY,
          });
        }

        //AA板初始化
        this.aaBan();

        //直条料初始化
        if (data.ztl && data.ztl.length > 0) {
          let dataX = data.ztl.map((item) => item.name);
          let dataY = data.ztl.map((item) => item.count);
          this.renderSortCard("sort_third_list", {
            dataX: dataX,
            dataY: dataY,
          });
        }
      });
      //表格数据初始化
      this.surveyListInit();
    },
    //AA板初始化
    aaBan() {
        let x = ["cm14", "cm16", "cm18", "cm20", "cm22", "cm24", "其它"];
        let dataX = ["14cm", "16cm", "18cm", "20cm", "22cm", "24cm", "其它"];
        if (
            this.survey.zjb_kind &&
            this.survey.zjb_kind[this.tabIndex] &&
            this.survey.zjb_kind[this.tabIndex].length > 0
        ) {
            // let dataX = this.survey.zjb_kind[this.tabIndex].map((item) => item.spec)
            let dataY = x.reduce((pre,cur,idx)=>{
                let aa = 0;
                this.survey.zjb_kind[this.tabIndex].forEach(e => {
                    if (e.spec==cur) aa = e.count;
                });
                pre.push(aa);
                return pre;
            },[])
            this.renderSortCard("sort_second_list", {
                dataX: dataX,
                dataY: dataY,
            });
        } else {
            this.renderSortCard("sort_second_list", {
                dataX: dataX,
                dataY: [],
            });
        }
    },
    /**
     * @desc 获得数据看板表格数据
     * @return {total:number,list:[]}
     */
    async getSurveyList() {
      let res = {};
      const params = {
        where: this.pageConfig.where,
        page: this.pageConfig.page,
      };
      await surveyList(params).then((data) => {
        res = data;
      });
      return res;
    },
    /**
     * @desc 渲染看板表格
     */
    surveyListInit() {
      this.list = [];
      const _loading = this.$loading({
          lock: true,
          target:this.$refs['load'],
          text: '拼命加载中......',
      });
      this.getSurveyList().then((data) => {
        console.log(data);
        this.pageConfig.total = data.count;
        this.list = data.rows;
        this.list.forEach((e)=>{
            e.context = this.select.reduce((pre,cur)=>{
                let aa = null;
                if (e.detail && e.detail.effect.length>0) {
                  e.detail.effect.forEach((n)=>{
                    if (cur.id==n) {
                      if (cur.name == '其他') {
                        aa = e.detail.effect_other;
                        console.log(e.detail.effect_other);
                      } else {
                        aa = cur.name;
                      }
                    }
                  })
                }
                if (aa) pre.push(aa);
                return pre;
            },[])
            e.context = e.context.join('，');
        })
        _loading.close();
      });
    },
    /**
     * @desc 渲染指接板 | AA板 | 直条料 图表
     */
    renderSortCard(id, { dataX, dataY }) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById(id));
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "0%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: dataX,
        },
        series: [
          {
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "right", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#595959",
                    fontSize: 12,
                  },
                },
                color: function (params) {
                  //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                  var colorList = ["#5AD8A6", "#73A0FA"];
                  return colorList[params.dataIndex % 2];
                },
              },
            },
            barCategoryGap: "40%",
            type: "bar",
            data: dataY,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      var autoHeight = dataY.length * 40 + 40;
      myChart.getDom().style.height = autoHeight + "px";
      myChart.resize();
    },
    /**
     * @desc 搜索条件改变时触发的事件
     * @return void
     */
    companyTypeChanged() {
      // console.log(this.selectConfig, this.key, this.pageConfig.page)
      this.surveyListInit();
    },
  },
};
</script>
<style scoped src="./style.css"></style>
<style scoped lang="less">
.zt-page-content {
  .block-header {
    font-size: 16px;
    color: #000;
    line-height: 20px;
    padding: 22px;
    background: #ffffff;
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    margin: 16px 16px 0 16px;
    > div:nth-child(2) {
      font-size: 18px;
      color: #e02020;
      margin-left: 20px;
    }
  }
  .page-overview {
    margin: 16px;
    min-height: 454px;
    box-sizing: border-box;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: #000000;
      div:last-child {
        font-size: 14px;
        font-weight: 400;
        color: #808080;
      }
    }
    > div {
      flex: 1;
      & + div {
        margin-left: 16px;
      }
    }
    ::v-deep .el-card {
      height: 454px;
      overflow: auto;
      // width: 400px;
      .el-card__body {
        overflow: auto;
        > div[id^="sort"] {
          min-height: 300px;
          margin-top: 50px;
        }
      }
    }
    .tab_menu {
      width: 280px;
      margin: 10px auto 0;
      overflow: hidden;
      .menu_item {
        padding: 0 17px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-right: none;
        cursor: pointer;
        font-size: 14px;
        color: #4d4d4d;
      }
      .menu_item:first-child {
        border-right: none;
        border-radius: 4px 0 0 4px;
      }
      .menu_item:last-child {
        border-right: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0 4px 4px 0;
      }
      .menu_item.cur {
        color: #ffffff;
        background: #1890ff;
        border: 1px solid #1890ff;
      }
    }
  }
  .box-card-head {
        padding: 15px;
        border-radius: 4px;
        border: 1px solid #e6e6e6;
        background: #e6e6e6 !important;
        box-shadow:1px 2px 4px 0px rgba(0,0,0,0.16);
        margin: 16px;
    }
  .page-body {
    min-height: 400px;
    margin: 16px;
    .list-item {
        margin-bottom: 16px;
    }
    .list-item:last-child {
        margin-bottom: 0;
    }
    .list-item-head {
        padding: 16px 32px;
        background: #F5F7FF;
        font-size: 14px;
        color: #808080;
        line-height: 20px;
        >div:first-child {
            margin-bottom: 10px;
        }
        span {
            color: #4D4D4D;
        }
    }
    .list-item-box {
        padding: 20px 30px;
        background: #fff;
        box-shadow:1px 2px 4px 0px rgba(0,0,0,0.16);
        border-radius:8px 8px 0 0;
        .title {
            font-size: 16px;
            font-weight: 600;
            color: #292929;
        }
        .table {
            padding-left: 100px;
            // border: 1px solid #EBEEF5;
        }
    }
    .list-item-box:last-child {
        border-radius:0 0 8px 8px;
    }
  }
}
</style>
